import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3257058b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab-menu-group" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "switch-table-list-wrap" }
const _hoisted_4 = { class: "switch-table-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_all_games = _resolveComponent("icon-all-games")!
  const _component_switch_table_item = _resolveComponent("switch-table-item")!
  const _component_pc_nui_item = _resolveComponent("pc-nui-item")!

  return (_openBlock(), _createElementBlock("div", {
    class: "switch-table-root",
    onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)), ["self"]))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["switch-table-content", _ctx.show ? 'show' : 'hiden'])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["tab-item", { active: _ctx.tabId === 1 }]),
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.switchTab(1)), ["stop"])),
          onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showOtherOptions = false))
        }, [
          _createVNode(_component_icon_all_games, {
            class: "icon",
            isOn: _ctx.tabId === 1
          }, null, 8, ["isOn"]),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('loby.allgames')), 1)
        ], 34),
        _createVNode(_Transition, { name: "fadein-out" }, {
          default: _withCtx(() => [
            (_ctx.showOtherOptions)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "other-options",
                  onMouseleave: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showOtherOptions = false))
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["baccarat-option", { active: _ctx.otherOption.indexOf(0) !== -1 }]),
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setOtherOptions(0)))
                  }, null, 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["dragon-tiger-option", { active: _ctx.otherOption.indexOf(1) !== -1 }]),
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setOtherOptions(1)))
                  }, null, 2)
                ], 32))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabId === 0 ? _ctx.goodWayTables : _ctx.desks, (desk) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "switch-table-list-item",
              key: `${desk.id}-witch-table-list-item`
            }, [
              (desk.gameTypeNo !== 'G001')
                ? (_openBlock(), _createBlock(_component_switch_table_item, {
                    key: 0,
                    activeDesk: -1,
                    mData: desk,
                    display: _ctx.displayOption,
                    onClick: ($event: any) => (_ctx.$emit('enter-table', desk.id, desk)),
                    class: "item"
                  }, null, 8, ["mData", "display", "onClick"]))
                : (_openBlock(), _createBlock(_component_pc_nui_item, {
                    key: 1,
                    desk: desk,
                    class: "item",
                    onClick: ($event: any) => (_ctx.$emit('enter-table', desk.id, desk))
                  }, null, 8, ["desk", "onClick"]))
            ]))
          }), 128))
        ])
      ])
    ], 2)
  ]))
}