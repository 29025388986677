
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    LimitOption,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    PropType,
    ref,
    ResponseData,
    TableData,
    UserInfo,
    watch
} from 'vue'

import {
    SvgExclamation,
    IconUserInfo,
    SvgDolar,
    SvgRecord,
    SvgArrowDown2,
    iconTopUp,
    iconWithdraw,
    iconWithdrawRecord
} from '@/components/icons'
import { EventBuss } from '@/types/global'
import {
    DialogBoxName,
    MessageBoxActionType,
    MessageBoxEvent
} from '@/types/MessageBox'
import { useStore } from '@/store'
import { getOneCommission, switchLimit } from '@/api'
import { AppMutation } from '@/store/types'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router'
import DUserInfo from '@/components/d-user-info.vue'
import Dropdown from '@/components/drop-down.vue'
import SwitchTable from '@/components/switch-table/landscape/switch-table.vue'
import MSwitchTable from '@/components/switch-table/portrait/portrait-switch-table.vue'
import { useI18n } from 'vue-i18n'
import { formatWithCommas } from '@/utils'

export default defineComponent({
    components: {
        'svg-exclamation': SvgExclamation,
        'icon-user-info': IconUserInfo,
        'svg-dolar': SvgDolar,
        'svg-record': SvgRecord,
        'svg-arrow-down-2': SvgArrowDown2,
        'd-user-info': DUserInfo,
        'dropdown': Dropdown,
        'switch-table': SwitchTable,
        'm-switch-table': MSwitchTable,
        iconTopUp,
        iconWithdraw,
        iconWithdrawRecord
    },
    props: {
        desk: {
            default: -1,
            type: Number as PropType<number>
        },
        hall: {
            type: Number as PropType<number>,
            default: 1
        }
    },
    setup(props, context) {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const router = useRouter()
        const { t } = useI18n()

        const showAllMenu = ref(process.env.SHOW_ALL_MENU === '0')
        const showMoreInfo = ref(false)
        const showSwitchDesk = ref(false)
        const showMobileSwitchDesk = ref(false)

        const limitOption = ref(-1)

        const refContent = ref()

        //#region Vue Helpers

        onMounted(() => {
            const limit = limitOptionArray.value.find(
                (s) => s.id === userLimit.value
            )

            if (limit) {
                limitOption.value = limitOptionArray.value.indexOf(limit)
            }
        })

        onBeforeMount(() => {
            if (props.desk <= 0) {
                router.push({ name: ROUTES.LOBY })
                return
            }

            emitter.on(EventBuss.WINDOW_RESIZED, handleOnWindowResize)
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.WINDOW_RESIZED, handleOnWindowResize)
        })

        //#endregion

        //#region Helpers

        const gotoCustumerService = () => {
            window.open(process.env.CUSTUMER_SERVICE, '_blank')
        }

        const formatNumberCurency = (n: number): string => {
            return formatWithCommas(n)
        }
        const onSwitchTable = (_tableNo: number, _desk: TableData) => {
            showSwitchDesk.value = false
            showMobileSwitchDesk.value = false

            if (props.desk === _tableNo) return

            const routeName = router.currentRoute.value.name
            const hallType = _desk.gameHallType

            if (_desk.gameTypeNo !== 'G001') {
                if (hallType === 1 && routeName !== ROUTES.DEFAULT_GAME) {
                    router.push({
                        name: ROUTES.DEFAULT_GAME,
                        query: {
                            desk: _tableNo,
                            hall: props.hall
                        }
                    })
                } else if (
                    hallType === 1 &&
                    routeName === ROUTES.DEFAULT_GAME
                ) {
                    router.replace({
                        query: {
                            desk: _tableNo,
                            hall: props.hall
                        }
                    })
                } else if (
                    hallType === 2 &&
                    routeName !== ROUTES.CLASSIC_GAME
                ) {
                    router.push({
                        name: ROUTES.CLASSIC_GAME,
                        query: {
                            desk: _tableNo,
                            hall: props.hall
                        }
                    })
                } else if (
                    hallType === 2 &&
                    routeName === ROUTES.CLASSIC_GAME
                ) {
                    router.replace({
                        query: {
                            desk: _tableNo,
                            hall: props.hall
                        }
                    })
                }
            } else if (_desk.gameTypeNo === 'G001') {
                if (hallType === 1 && routeName !== ROUTES.NIU_NIU) {
                    router.push({
                        name: ROUTES.NIU_NIU,
                        query: {
                            desk: _tableNo,
                            hall: props.hall
                        }
                    })
                } else if (hallType === 1 && routeName === ROUTES.NIU_NIU) {
                    router.replace({
                        query: {
                            desk: _tableNo,
                            hall: props.hall
                        }
                    })
                } else if (
                    hallType === 2 &&
                    routeName !== ROUTES.NIU_NIU_CLASSIC
                ) {
                    router.push({
                        name: ROUTES.NIU_NIU_CLASSIC,
                        query: {
                            desk: _tableNo,
                            hall: props.hall
                        }
                    })
                } else if (
                    hallType === 2 &&
                    routeName === ROUTES.NIU_NIU_CLASSIC
                ) {
                    router.replace({
                        query: {
                            desk: _tableNo,
                            hall: props.hall
                        }
                    })
                }
                // console.log(routeName)

                // if (routeName !== ROUTES.NIU_NIU) {
                // router.push({
                //     name: ROUTES.NIU_NIU,
                //     query: {
                //         desk: _tableNo,
                //         hall: props.hall
                //     }
                // })
                // } else {
                //     router.replace({
                //         query: {
                //             desk: _tableNo,
                //             hall: props.hall
                //         }
                //     })
                // }
            }
        }

        const handleOnWindowResize = () => {
            showSwitchDesk.value = false
            showMobileSwitchDesk.value = false
        }

        //#endregion

        //#region Click Helpers

        const showDialog = (name: DialogBoxName) => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const setHall = (_hall: number) => {
            if (_hall === 5) router.push({ name: ROUTES.MULTI })
            else router.push({ name: ROUTES.LOBY, query: { hall: _hall } })
        }

        //#endregion

        //#region API Call Helpers

        const getCommissionInfo = () => {
            const params = {
                memberId: memberid.value,
                token: token.value
            }

            store.commit(AppMutation.SHOW_LOADING)

            getOneCommission(params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (response.data.status === 100) {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            response.data.description
                        )
                    } else if (response.data.status === 200) {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            t('hints.commissionconvertionsuccessful')
                        )
                        store.commit(
                            AppMutation.SET_COIN,
                            response.data.data.amount
                        )
                        store.commit(AppMutation.SET_COMMISSION, '0')
                    }
                })
                .catch((e) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        t('prompt.networkerror')
                    )
                })
        }

        const switchUserLimit = (limit: LimitOption) => {
            const params = {
                memberId: memberid.value,
                limitId: limit.id,
                token: token.value
            }

            store.commit(AppMutation.SHOW_LOADING)

            switchLimit(params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)

                    const responseData = response.data as ResponseData<any>

                    if (responseData.status === 200) {
                        const limitId =
                            limitOptionArray.value[limitOption.value]
                        store.commit(AppMutation.SET_LIMIT, limitId.id)
                    } else if (
                        responseData.status === 100 ||
                        responseData.status === 301
                    ) {
                        // token is expred
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            responseData.description!
                        )

                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        '当前网络不佳，请重新尝试'
                    )
                })
        }

        //#endregion

        //#region Computed
        const memberid = computed(() => store.getters['memberid'])
        const token = computed(() => store.getters['token'])
        const avatar = computed(() => store.getters['avatar'])
        const userLimit = computed((): number => store.getters.userLimit)
        const user = computed(() => store.getters['user'])
        const coin = computed(() => store.getters['coin'])
        const commission = computed(() => store.getters['commission'])

        const limitOptionArray = computed(
            (): LimitOption[] => store.getters['limits']
        )

        const hasAnchorTables = computed(
            (): boolean => store.getters['hasAnchorTables']
        )

        const hasClassicTables = computed(
            (): boolean => store.getters['hasClassicTables']
        )
        //#endregion

        //#region Watchers
        watch(
            () => limitOption.value,
            (newValue, oldValue) => {
                if (oldValue !== -1)
                    switchUserLimit(limitOptionArray.value[newValue])
            }
        )

        //#endregion

        return {
            commission,
            hasAnchorTables,
            hasClassicTables,
            user,
            coin,
            avatar,
            showAllMenu,
            userLimit,
            showMoreInfo,
            limitOptionArray,
            limitOption,
            refContent,
            showSwitchDesk,
            showMobileSwitchDesk,
            onSwitchTable,
            showDialog,
            setHall,
            t,
            getCommissionInfo,
            formatNumberCurency,
            gotoCustumerService
        }
    }
})
