
import {
    computed,
    defineComponent,
    Emitter,
    HallData,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref,
    ResponseData,
    SocketMessage,
    TableData
} from 'vue'
import SwitchTableItem from '@/components/switch-table/landscape/switch-table-item.vue'
import SwitchTableNuiItem from '@/components/switch-table/landscape/switch-table-nui-item.vue'
import { getHallData } from '@/api'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss, SOCKET_MESSAGE } from '@/types/global'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router'
import {
    IconThumbsUp,
    IconAllGames,
    IconBaccarat,
    IconDragonTiger,
    IconStatistics,
    IconAllRoad,
    IconBigRoad,
    SvgNuinui
    // IconMultiTable
} from '@/components/icons'
import { parseRoadMap } from '@/components/roadmap/utils'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    emits: ['enter-table', 'close'],
    components: {
        'pc-nui-item': SwitchTableNuiItem,
        SwitchTableItem,
        // IconThumbsUp,
        IconAllGames
        // IconBaccarat,
        // IconDragonTiger,
        // IconStatistics,
        // IconAllRoad,
        // IconBigRoad,
        // SvgNuinui
    },
    setup(props, { emit }) {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const { t } = useI18n()

        const tabId = ref<number>(1)
        const router = useRouter()
        const displayOption = ref(0)
        const show = ref(false)
        const desks = ref<TableData[]>([])
        const otherOption = ref([0, 1])
        const showOtherOptions = ref(false)

        let loadDataFailedCounter = 0
        let reloadAPICallTimeOut: boolean | number | any = false

        //#region  Vue Methods
        onMounted(() => {
            loadData()
            show.value = true
        })

        onBeforeMount(() => {
            // listen to websocket events
            emitter.on(EventBuss.SOCKET_MESSAGE, handleSocketMessage)
        })

        onBeforeUnmount(() => {
            // unregister events
            emitter.off(EventBuss.SOCKET_MESSAGE, handleSocketMessage)

            if (typeof reloadAPICallTimeOut === 'number') {
                clearTimeout(reloadAPICallTimeOut)
                reloadAPICallTimeOut = false
            }
        })
        //#endregion

        const handleSocketMessage = (_message: SocketMessage) => {
            const _type = _message.type.toUpperCase()
            if (
                _type === SOCKET_MESSAGE.NEW_GAME ||
                _type === SOCKET_MESSAGE.END_GAME ||
                _type === SOCKET_MESSAGE.STOP_GAME ||
                _type === SOCKET_MESSAGE.NEW_SHOE
            ) {
                const _index = desks.value.findIndex(
                    (_d) => _d.id === _message.gameTable!.id
                )

                if (_index !== -1) {
                    const _copyDesk = { ...desks.value[_index] }
                    const sDesk = _message.gameTable as TableData

                    if (_type === SOCKET_MESSAGE.NEW_GAME) {
                        _copyDesk.expand.shoeNo = sDesk.expand.shoeNo
                        _copyDesk.expand.juNo = sDesk.expand.juNo
                        _copyDesk.expand.gameNo = sDesk.expand.gameNo
                        _copyDesk.expand.daoJiShiBetTime =
                            sDesk.expand.daoJiShiBetTime

                        _copyDesk.status = 1
                        desks.value[_index] = _copyDesk
                    } else if (_type === SOCKET_MESSAGE.STOP_GAME) {
                        _copyDesk.expand.daoJiShiBetTime = 0
                        _copyDesk.status = 2
                        desks.value[_index] = _copyDesk
                    } else if (_type === SOCKET_MESSAGE.END_GAME) {
                        _copyDesk.expand.resultList = sDesk.expand.resultList
                        _copyDesk.status = sDesk.status
                        desks.value[_index] = _copyDesk
                    }
                }
            }
        }

        const setOtherOptions = (num: number) => {
            const index = otherOption.value.indexOf(num)
            if (index === -1) {
                otherOption.value.push(num)
            } else {
                otherOption.value.splice(index, 1)
            }

            loadData()
        }

        const switchTab = (_tabId: number) => {
            if (_tabId !== tabId.value) {
                tabId.value = _tabId
                loadData()
            }
        }

        const loadData = (_forceReload = false) => {
            if (!_forceReload) store.commit(AppMutation.SHOW_LOADING)

            const urlParams = new URLSearchParams(window.location.search)
            // const hall = urlParams.get('hall') || 1

            const _params: any = {
                token: token.value,
                gameHallType: 0
            }

            if (tabId.value === 0) {
                _params.goodWayRecommend = 1
                let _opt = 0

                if (
                    otherOption.value.length === 2 ||
                    otherOption.value.length === 0
                ) {
                    _opt = 3
                } else {
                    _opt = otherOption.value[0]
                }

                if (_opt === 0) {
                    _params.gameTypeNo = 'G002'
                } else if (_opt === 1) {
                    _params.gameTypeNo = 'G003'
                }
            } else {
                if (tabId.value === 2) _params.gameTypeNo = 'G002'
                else if (tabId.value === 3) _params.gameTypeNo = 'G003'
                else if (tabId.value === 5) _params.gameTypeNo = 'G001'
                _params.goodWayRecommend = -1

                // if (hall === '2' && tabId.value === 1) {
                //     _params.gameHallType = 0
                // }
            }

            getHallData(_params)
                .then((response) => {
                    loadDataFailedCounter = 0
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)
                    const responseData = response.data as ResponseData<HallData>
                    if (responseData.status === 200) {
                        // console.log(responseData)
                        desks.value = responseData.data!.gameTableList
                        store.commit(
                            AppMutation.SET_USER_GUNNER_BIND,
                            responseData.data?.bindGunner || false
                        )

                        if (
                            isFreeUser.value &&
                            visitorTable.value.length === 0
                        ) {
                            let _tempDesk = [...desks.value]
                            const _visitorDeskNum: number[] = []

                            for (
                                let _deskIndex = 0;
                                _deskIndex < desks.value.length;
                                _deskIndex++
                            ) {
                                if (_visitorDeskNum.length === 3) {
                                    break
                                } else {
                                    const _desk =
                                        _tempDesk[
                                            Math.floor(
                                                Math.random() * _tempDesk.length
                                            )
                                        ]
                                    if (_desk) {
                                        _tempDesk.splice(
                                            _tempDesk.indexOf(_desk),
                                            1
                                        )
                                        _visitorDeskNum.push(_desk.id)
                                    }
                                }
                            }
                            store.commit(
                                AppMutation.SET_VISITOR_DESK,
                                _visitorDeskNum
                            )
                        }

                        let _lobyNoticeList = responseData.data!.noticeList
                        let _lobyNotice = ''

                        _lobyNoticeList.forEach((_notice: any, index) => {
                            // console.log(_notice)
                            if (index === 0) _lobyNotice = _notice.content
                            else
                                _lobyNotice = `${_lobyNotice},   ${_notice.content}`
                        })

                        // set multipliers
                        const { boomPingBonus, boomFanBonus, boomDuoBonus } =
                            responseData.data!

                        const multiplier = {
                            evenMultiplier: 0,
                            doubleMultiplier: 0,
                            superMultiplier: 0
                        }

                        if (boomDuoBonus)
                            multiplier.superMultiplier = boomDuoBonus
                        if (boomFanBonus)
                            multiplier.doubleMultiplier = boomFanBonus
                        if (boomPingBonus)
                            multiplier.evenMultiplier = boomPingBonus

                        store.commit(AppMutation.SET_MULTIPLIERS, multiplier)
                    } else if (
                        responseData.status === 100 ||
                        responseData.status === 301
                    ) {
                        // token is expred
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            responseData.description!
                        )

                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    }
                })
                .catch((e) => {
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)
                    loadDataFailedCounter++
                    if (loadDataFailedCounter > 3) {
                        emitter.emit(
                            EventBuss.SMALL_TOAST,
                            '当前网络不佳，请重新尝试'
                        )
                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    } else {
                        // reload after 1 second
                        reloadAPICallTimeOut = setTimeout(() => {
                            loadData(true)
                        }, 1500)
                    }
                })
        }

        const toggleDisplayOptionView = () => {
            if (displayOption.value === 2) {
                displayOption.value = 0
            } else {
                displayOption.value++
            }
        }

        const onClose = () => {
            show.value = false
            setTimeout(() => {
                emit('close')
            }, 10)
        }

        const goodWayTables = computed((): TableData[] => {
            if (goodWay.value.length === 0) return []

            const result: TableData[] = []

            desks.value.forEach((_t) => {
                const mapData = parseRoadMap(_t.expand.resultList)

                if (mapData.goodway.length > 0) {
                    const _ids = mapData.goodway.map((_goodWay) => _goodWay.id)
                    for (let index = 0; index < goodWay.value.length; index++) {
                        const i = goodWay.value[index]
                        if (_ids.indexOf(i) !== -1) {
                            result.push(_t)
                            break
                        }
                    }
                }
            })

            return result
        })

        const goodWay = computed((): number[] => store.getters['goodWay'])

        const token = computed(() => store.getters['token'])
        const commandType = computed(() => store.getters['commandType'])
        const isFreeUser = computed(() => store.getters['isFreeUser'])
        const visitorTable = computed(
            (): number[] => store.getters['visitorTable']
        )

        const displayOptionLabel = computed(() => {
            const displayValue = displayOption.value
            if (displayValue === 0) return t('common.allroad')
            else if (displayValue === 1) return t('common.mainroad')
            else return t('common.statistics')
        })

        return {
            tabId,
            desks,
            displayOption,
            displayOptionLabel,
            show,
            showOtherOptions,
            otherOption,
            goodWayTables,
            setOtherOptions,
            switchTab,
            onClose,
            toggleDisplayOptionView,
            t
        }
    }
})
